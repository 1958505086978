import { useRoutes } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@material-ui/core';
import './config/i18n';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { esES } from '@material-ui/core/locale';
import GlobalStyles from './components/GlobalStyles';
import SplashScreen from './components/SplashScreen';
import useAuth from './hooks/useAuth';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import routes from './routes';
import { createTheme } from './theme';

const App = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();
  useScrollReset();

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
    localization: esES
  });
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Lce48YZAAAAAM6BZWELmBs9k9Ba1w9o4PsqhDQf">
      <ThemeProvider theme={theme}>
        <SnackbarProvider dense maxSnack={3} style={{ marginBottom: 20 }}>
          <GlobalStyles />
          {auth.isInitialized ? content : <SplashScreen />}
        </SnackbarProvider>
      </ThemeProvider>
    </GoogleReCaptchaProvider>
  );
};

export default App;
