import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Avatar, Box, Button, Divider, Drawer, Hidden, Link, Typography } from '@material-ui/core';
import Logo from 'src/components/LogoCavem1';
import { History, Assessment, ShowChart } from '@material-ui/icons';
import useAuth from '../../hooks/useAuth';
import NavSection from './NavSection';
import Scrollbar from '../../components/Scrollbar';

const sections = [
  {
    title: 'General',
    items: [
      {
        title: 'BUSCADOR DE PRECIOS',
        path: '/',
        icon: <ShowChart fontSize="small" />
      },
      {
        title: 'INFORMES',
        path: '/reports',
        icon: <Assessment fontSize="small" />
      },
      {
        title: 'HISTORIAL',
        path: '/historical_data',
        icon: <History fontSize="small" />
      }
    ]
  }
];

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              p: 2
            }}
          >
            <RouterLink to="/">
              <Logo height={80} width={80} />
            </RouterLink>
          </Box>
        </Hidden>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2
            }}
          >
            <RouterLink to="/">
              <Avatar
                src={user.avatar}
                sx={{
                  cursor: 'pointer',
                  height: 48,
                  width: 48
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography color="textPrimary" variant="subtitle2">
                {user.name}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                {user.email}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
        <Box sx={{ p: 2 }} hidden>
          <Typography color="textPrimary" variant="subtitle2" align="center">
            ¿Necesitas Ayuda?
          </Typography>
          <Typography color="textSecondary" variant="body2" align="justify">
            Contáctanos a
            {' '}
            <Link href="correo@cavem.cl">correo@cavem.cl</Link>
            {' '}
            o haga click en el siguiente botón
          </Typography>
          <Button color="primary" component={RouterLink} fullWidth sx={{ mt: 2 }} to="/" variant="contained">
            Solicitar Ayuda
          </Button>
        </Box>
      </Scrollbar>
    </Box>
  );

  return (
    <>
      <Hidden mdUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              width: 280
            }
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
