import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/lib/axios';

const initialState = {
  vehicle: {
    license_plate: '',
    brand_id: '',
    model_id: '',
    version_id: '',
    region_id: '',
    year: '',
    km: '',
    period: '3',
    version_name: '',
    region_name: '',
    model_image: '',
    model_name: '',
    brand_name: '',
    showName: '',
    color: '',
    with_report: false,
    characteristics: {
      cylinder_capacity: '',
      fuel_type: '',
      abs: '',
      air_conditioner: '',
      alarm: '',
      central_lock: '',
      rims: '',
      checkup: '',
      condition: '',
      key_spare: '',
      steering: '',
      seat_runs: '',
      owners_number: '',
      collateral: '',
      transmission: '',
      traction: '',
      airbags_number: '',
      circulation_permit: '',
      doors_number: '',
      inspection: '',
      sun_roof: '',
      tapestry: '',
      funding: '',
      vehicle_status: '',
      options_condition: '',
      additionalFields: '',
      equipmentFields: '',
      translate: '',
      vehicle_type: '',
      vehicle_inspection: '',
      vehicle_has_stock: '',
      soat: '',
      apps: '',
      zofri: '',
      billable: ''
    },
  },
  brands: [],
  models: [],
  years: [],
  versions: [],
  regions: [],
  users: [],
  error: null
};

const slice = createSlice({
  name: 'vehicle',
  initialState,
  reducers: {
    setVehicle(state, action) {
      state.vehicle = action.payload;
    },
    setBrands(state, action) {
      state.brands = action.payload;
    },
    setRegions(state, action) {
      state.regions = action.payload;
    },
    setUsers(state, action) {
      state.users = action.payload;
    },
    setModels(state, action) {
      state.models = action.payload;
    },
    setYears(state, action) {
      state.years = action.payload;
    },
    setVersions(state, action) {
      state.versions = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    clean(state) {
      state.vehicle = initialState.vehicle;
      state.models = [];
      state.years = [];
      state.versions = [];
    }
  }
});

export const { reducer } = slice;

export const getBrands = () => async (dispatch) => {
  const response = await axios.get('/brands');

  dispatch(slice.actions.setBrands(response.data));
};
export const getModels = (value) => async (dispatch) => {
  const response = await axios.post('/models/by_brand', { brand_id: value });

  dispatch(slice.actions.setModels(response.data));
};
export const getYears = (value) => async (dispatch) => {
  const response = await axios.post('/vehicle/years', { model_id: value });
  const years = response.data.map((v) => ({ id: v, name: v }));
  dispatch(slice.actions.setYears(years));
};
export const getVersions = (value) => async (dispatch) => {
  const response = await axios.post('/vehicle/versions', { vehicle_version: value });
  const { versions } = response.data;
  const isNew = response.data.new_version;
  if (isNew) {
    dispatch(slice.actions.setVersions([...versions, { id: -2, name: 'Ver todas las versiones del año' }]));
  } else {
    dispatch(slice.actions.setVersions([...versions, { id: -2, name: 'Ver todas las versiones del año' }, { id: -1, name: 'Ver todas las versiones del modelo' }]));
  }
};

export const getVersionsReports = (value) => async (dispatch) => {
  const response = await axios.post('/vehicle/versions', { vehicle_version: value });
  const { versions } = response.data;
  dispatch(slice.actions.setVersions(versions));
};

export const getRegions = () => async (dispatch) => {
  try {
    const response = await axios.get('/price_monitor/regions');
    const data = (response.data || []).map((r) => ({ id: r.id, name: r.name }));

    dispatch(slice.actions.setRegions(data));
  } catch (error) {
    dispatch(slice.actions.setError('Error al consultar las regiones'));
  }
};

export const getUsers = () => async (dispatch) => {
  try {
    const response = await axios.get('/users');
    dispatch(slice.actions.setUsers(response.data));
  } catch (error) {
    dispatch(slice.actions.setError('Error al consultar los usuarios'));
  }
};

export const getInfo = (value, currentValues, findBestVersion) => async (dispatch) => {
  try {
    const response = await axios.post('/vehicle/vehicle_info', { license_plate: value });
    // eslint-disable-next-line prefer-const
    let { years, versions, ...rest } = response.data;
    const isNew = response.data.new_version;

    versions = versions.map((v) => ({ ...v, name: v.version }));
    years = years.map((v) => ({ id: v, name: v }));
    const vehicle = {
      license_plate: value.toUpperCase(),
      brand_id: rest.brand_id,
      model_id: rest.model_id,
      version_id: (findBestVersion) ? rest.best_version : '',
      model_name: rest.model_name,
      brand_name: rest.brand_name,
      year: rest.year,
      model_image: rest.model_image,
      showName: rest.showName,
      color: rest.color,
      characteristics: {
        cylinder_capacity: rest.cylinder_capacity,
        abs: '',
        air_conditioner: '',
        alarm: '',
        central_lock: '',
        rims: '',
        checkup: '',
        condition: '',
        key_spare: '',
        steering: '',
        seat_runs: '',
        fuel_type: rest.fuel_type,
        owners_number: '',
        collateral: '',
        transmission: rest.transmission,
        traction: rest.traction,
        airbags_number: '',
        circulation_permit: '',
        doors_number: '',
        inspection: '',
        sun_roof: '',
        tapestry: '',
        funding: '',
        vehicle_status: '',
        options_condition: '',
        additionalFields: '',
        equipmentFields: '',
        translate: '',
        vehicle_type: '',
        vehicle_inspection: '',
        vehicle_has_stock: '',
        soat: '',
        apps: '',
        zofri: '',
        billable: ''
      },
    };
    const models = await axios.post('/models/by_brand', { brand_id: rest.brand_id });

    dispatch(slice.actions.setModels(models.data));
    if (isNew) {
      dispatch(slice.actions.setVersions([...versions, { id: -2, name: 'Ver todas las versiones del año' }]));
    } else {
      dispatch(slice.actions.setVersions([...versions, { id: -2, name: 'Ver todas las versiones del año' }, { id: -1, name: 'Ver todas las versiones del modelo' }]));
    }
    dispatch(slice.actions.setYears(years));
    dispatch(slice.actions.setVehicle({ ...currentValues, ...vehicle }));
  } catch (error) {
    dispatch(slice.actions.setError('Error al consultar la información del vehículo'));
  }
};
export const setError = () => async (dispatch) => {
  dispatch(slice.actions.setError(null));
};

export const cleanVehicle = () => async (dispatch) => {
  dispatch(slice.actions.clean());
};

export default slice;
