import { useEffect } from 'react';
import NProgress from 'nprogress';
import { Box, CircularProgress } from '@material-ui/core';

const LoadingScreen = () => {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        minHeight: '100%',
        py: '10%'
      }}
      display="flex"
      justifyContent="center"
    >
      <Box>
        <CircularProgress />
      </Box>
    </Box>
  );
};

export default LoadingScreen;
