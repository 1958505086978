import axios from 'axios';

const axiosInstance = axios.create();

axiosInstance.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;

// const { CancelToken } = axiosInstance;
// let cancel;

// axiosInstance.interceptors.request.use(
//   (config) => {
//     if (cancel) {
//       cancel(); // cancel request
//     }

//     config.cancelToken = new CancelToken((c) => {
//       cancel = c;
//     });

//     return config;
//   },
//   (error) => Promise.reject(error)
// );

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 500) {
      return window.location.reload();
    }
    return Promise.reject((error.response && error.response.data) || 'Ocurrió un error al comunicarse con el servidor');
  }
);

export default axiosInstance;
