import React, { Suspense, lazy } from 'react';
import AuthGuard from './components/AuthGuard';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import Layout from './views/layout/Layout';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Authentication views
const Login = Loadable(lazy(() => import('./views/auth/Login')));
const PasswordRecovery = Loadable(lazy(() => import('./views/auth/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./views/auth/PasswordReset')));

// APP views
const Home = Loadable(lazy(() => import('./views/priceMonitor/index')));
const HistoricalData = Loadable(lazy(() => import('./views/historicalData')));
const Report = Loadable(lazy(() => import('./views/report')));

// Error views
const AuthorizationRequired = Loadable(lazy(() => import('./views/errors/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./views/errors/NotFound')));
const ServerError = Loadable(lazy(() => import('./views/errors/ServerError')));

const routes = [
  {
    path: '/auth',
    children: [
      {
        path: '/login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: '/password-recovery',
        element: (
          <GuestGuard>
            <PasswordRecovery />
          </GuestGuard>
        )
      },
      {
        path: '/password-reset',
        element: <PasswordReset />
      }
    ]
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: '/historical_data',
        element: <HistoricalData />
      },
      {
        path: '/reports',
        element: <Report />
      }
    ]
  },
  {
    path: '*',
    element: '',
    children: [
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
