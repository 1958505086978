/* eslint-disable jsx-a11y/label-has-associated-control */
import { Formik, Field } from 'formik';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { Box, Button, Grid, TextField, FormHelperText, CircularProgress } from '@material-ui/core';
import SelectField from 'src/components/SelectField';
import QuillEditor from 'src/components/QuillEditor';
import _ from 'lodash';
import schema from './Schema';

const Form = ({ handleClose, handleSave }) => {
  const isMountedRef = useIsMountedRef();

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        minHeight: '100%',
        p: 3
      }}
    >
      <Formik
        initialValues={{ comment: '', file: null, option: '' }}
        validationSchema={schema}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            await handleSave(values);

            if (isMountedRef.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (e) {
            // console.error(e);
            if (isMountedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: e.message });
              setSubmitting(false);
            }
          }
        }}
      >
        {(rest) => (
          <form autoComplete="on" onSubmit={rest.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SelectField
                  label="Tipo de consulta"
                  name="option"
                  options={[
                    { id: 'Ayuda', name: 'Ayuda' },
                    { id: 'Feedback', name: 'Feedback' },
                    { id: 'Reclamos', name: 'Reclamos' }
                  ]}
                  {...rest}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Field name="comment">
                  {({ field }) => <QuillEditor placeholder="Comentarios" value={field.value} onChange={field.onChange(field.name)} />}
                </Field>
                <FormHelperText error>{rest.touched.comment && rest.errors.comment}</FormHelperText>
              </Grid>
              <Grid item xs={9}>
                <TextField size="small" fullWidth disabled value={_.get(rest, 'values.file.name', 'Adjuntar imagen')} />
              </Grid>
              <Grid item xs={3}>
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  multiple
                  id="file"
                  name="file"
                  type="file"
                  onChange={(e) => rest.setFieldValue('file', e.currentTarget.files[0])}
                />
                <label htmlFor="file">
                  <Button variant="contained" size="large" component="span" fullWidth color="inherit">
                    BUSCAR
                  </Button>
                </label>
              </Grid>
              <Grid item xs={12} md={6} mt={2}>
                <Button color="secondary" fullWidth size="large" variant="contained" onClick={handleClose} disabled={rest.isSubmitting}>
                  CANCELAR
                </Button>
              </Grid>
              <Grid item xs={12} md={6} mt={2}>
                <Button color="primary" fullWidth size="large" variant="contained" type="sumbit" disabled={rest.isSubmitting}>
                  ENVIAR
                </Button>
                {rest.isSubmitting && (
                  <CircularProgress
                    size={24}
                    color="primary"
                    sx={{ position: 'absolute', ml: -15, mt: 1 }}
                  />
                )}
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default Form;
