import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      'Welcome to React': 'Welcome to React and react-i18next',
    }
  },
  es: {
    translation: {
      'Must be a valid email': 'Debe ser un correo electrónico válido',
      'Email is required': 'El correo es requerido',
      'Password is required': 'La contraseña es requerida',
      'Log In': 'INICIAR SESIÓN',
      'Password': 'Contraseña',
      'Email': 'Correo'
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'es',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });
