/* eslint-disable max-len */
import React from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import _ from 'lodash';

export default function CustomSelectField(props) {
  const { name, label, errors, handleBlur, handleChange, values, touched, options, required = false, defaultText = '' } = props;

  const finalValue = _.get(values, name);

  let finalOptions = options;
  if (name === 'min_age' && values.max_age) {
    finalOptions = options.filter((e) => e.id <= values.max_age);
  }
  if (name === 'max_age' && values.min_age) {
    finalOptions = options.filter((e) => e.id >= values.min_age);
  }

  return (
    <TextField
      size="small"
      error={Boolean(touched[name] && errors[name])}
      helperText={touched[name] && errors[name]}
      onBlur={handleBlur}
      onChange={handleChange}
      value={finalValue !== null ? finalValue : ''}
      id={name}
      label={label}
      variant="outlined"
      name={name}
      fullWidth
      select
      required={required}
      defaultValue=""
      InputLabelProps={{ shrink: true }}
    >
      {!required && (
        <MenuItem key="" value="">
          {defaultText}
        </MenuItem>
      )}
      {finalOptions.map((o) => (
        <MenuItem key={o.id} value={o.id}>
          {o.name}
        </MenuItem>
      ))}
    </TextField>
  );
}
